import "./App.css";
import Card from "@mui/material/Card";
import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import Lottie from "lottie-react";
import loadingSplashAnimation from "./loadingSplash.json";
import notFound from "./404.json";
import logo from "./logo.png";
import appstore from "./DownloadAppStore.png";
import playstore from "./DownloadPlayStore.png";
import { Link } from "react-router-dom";
import profilePicDefault from "./profilePicDefault.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Snackbar, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import TransgenderIcon from "@mui/icons-material/Transgender";
import CloseIcon from "@mui/icons-material/Close";
import CallIcon from "@mui/icons-material/Call";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import SchoolIcon from "@mui/icons-material/School";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import LanguageIcon from "@mui/icons-material/Language";
import HomeIcon from "@mui/icons-material/Home";
import CakeIcon from "@mui/icons-material/Cake";
import EmailIcon from "@mui/icons-material/Email";
import Home from "@mui/icons-material/Home";
import PhoneInTalk from "@mui/icons-material/PhoneInTalk";
import ApartmentIcon from "@mui/icons-material/Apartment";
import WorkIcon from "@mui/icons-material/Work";

const SERVER_API_ADDRESS = "https://idapi.techcards.in/";
const LOCAL_API_ADDRESS = "http://127.0.0.1:8000/";
const SERVER_SOCKET_ADDRESS = "wss://idapi.techcards.in/";
const LOCAL_SOCKET_ADDRESS = "ws://127.0.0.1:8000/";
const PRODUCTION = true;

const images = require.context("./icons", true);
var url = window.location.href.split("://")[1].split("/");
const profile_tapped_link = url[1];
const profile_tapped_uid = url[2];

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const [profile, setProfile] = useState({});
  const [found, setFound] = useState(false);
  const [profilePicError, setProfilePicError] = useState(false);
  const [schoolLogoError, setSchoolLogoError] = useState(false);

  function getData() {
    axios
      .get(
        PRODUCTION
          ? SERVER_API_ADDRESS +
              "profile_link/" +
              profile_tapped_link +
              "/" +
              profile_tapped_uid
          : LOCAL_API_ADDRESS +
              "profile_link/" +
              profile_tapped_link +
              "/" +
              profile_tapped_uid
      )
      .then((res) => {
        setProfile(res.data);
        setFound(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setFound(false);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  if (isLoading) {
    return (
      <div className="App">
        <div
          style={{
            height: "100vh",
            width: "98vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Lottie
            style={{ height: "300px", width: "300px" }}
            animationData={loadingSplashAnimation}
            loop={true}
          />
        </div>
      </div>
    );
  } else {
    if (found) {
      return (
        <div className="App">
          <div
            style={{
              height: "100%",
              width: "98vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: "45vh",
                width: "100%",
                zIndex: -1,
                position: "absolute",
                backgroundImage: "linear-gradient(to right, #50C878 , #96deae)",
              }}
            />
            <a
              href={"https://www.techcards.in"}
              style={{
                padding: "3px 10px 3px 10px",
                margin: "10px 10px 3px 10px",
                backgroundColor: "rgba(0,0,0,0.4)",
                fontSize: "20px",
                fontWeight: 400,
                textDecoration: "none",
                color: "white",
                position: "fixed",
                top: 0,
                left: 0,
              }}
            >
              Visit Site
            </a>

            <div
              style={{
                marginTop: "5vh",
                borderRadius: "50%",
                height: "150px",
                maxWidth: "500px",
                backgroundColor: "#145BC7",
              }}
            >
              {schoolLogoError ? (
                <img
                  style={{
                    objectFit: "cover",
                    borderRadius: "50%",
                    height: "100%",
                    width: "100%",
                  }}
                  src={profilePicDefault}
                />
              ) : (
                <img
                  onError={() => {
                    setSchoolLogoError(true);
                  }}
                  style={{
                    objectFit: "cover",
                    height: "100%",
                    width: "100%",
                  }}
                  src={
                    PRODUCTION
                      ? `${SERVER_API_ADDRESS}media/${profile.school_logo}`
                      : `${LOCAL_API_ADDRESS}media/${profile.school_logo}`
                  }
                />
              )}
            </div>

            <div
              style={{
                marginTop: "25px",
                fontSize: "40px",
                fontWeight: "bold",
                marginBottom: "75px",
                color: "white",
              }}
            >
              {profile.school_name}
            </div>
            <Grid container spacing={0} style={{ width: "100%" }}>
              <Grid item sm={1} md={2} lg={3} style={{ width: "95%" }}></Grid>
              <Grid item sm={10} md={8} lg={6} style={{ width: "95%" }}>
                <div
                  style={{
                    height: "175px",
                    padding: "15px",
                    width: "100%",
                    marginTop: "-40px",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "15px",
                    background: "linear-gradient(to right, #7433F0 , #A171FF)",
                    marginBottom: "30px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "50%",
                      height: "150px",
                      width: "150px",
                      backgroundColor: "#145BC7",
                    }}
                  >
                    {profilePicError ? (
                      <img
                        style={{
                          objectFit: "cover",
                          borderRadius: "50%",
                          height: "100%",
                          width: "100%",
                        }}
                        src={profilePicDefault}
                      />
                    ) : (
                      <img
                        onError={() => {
                          setProfilePicError(true);
                        }}
                        style={{
                          objectFit: "cover",
                          borderRadius: "50%",
                          height: "150px",
                          width: "150px",
                        }}
                        src={
                          PRODUCTION
                            ? `${SERVER_API_ADDRESS}media/${profile.user_image}`
                            : `${LOCAL_API_ADDRESS}media/${profile.user_image}`
                        }
                      />
                    )}
                  </div>
                  <div
                    style={{
                      marginLeft: "30px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "25px",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {profile.full_name}
                    </div>
                    <div
                      style={{
                        fontSize: "25px",
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      {profile.class_division}
                    </div>
                  </div>
                </div>
                <div style={{ marginBottom: "15px" }}>
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "15px",
                      color: "#145BC7",
                      borderBottom: "2px solid #145BC7",
                      paddingBottom: "15px",
                    }}
                  >
                    Personal Details
                  </div>
                  <a
                    href={"tel://" + profile.phone_number}
                    style={{
                      textDecoration: "none !important",
                      padding: "15px",
                      color: "#145BC7",
                      marginBottom: "15px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "left",
                          width: "100%",
                          marginRight: "20px",
                        }}
                      >
                        {profile.phone_number.length != 0 ? (
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "normal",
                              color: "black",
                              display: "flex",
                              borderBottom: "1px solid #ddd",
                              paddingBottom: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#253494",
                                  padding: "4px",
                                  borderRadius: "50px",
                                  height: "35px",
                                  width: "35px",
                                  marginRight: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <PhoneInTalkIcon
                                  style={{
                                    color: "white",
                                    fontSize: "30px",
                                  }}
                                />
                              </div>
                              Phone Number
                            </div>
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              {profile.phone_number}
                            </div>
                          </div>
                        ) : (
                          <Fragment></Fragment>
                        )}

                        {profile.email.length != 0 ? (
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "normal",
                              color: "black",
                              display: "flex",
                              borderBottom: "1px solid #ddd",
                              paddingBottom: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#2ca25f",
                                  padding: "4px",
                                  borderRadius: "50px",
                                  height: "35px",
                                  width: "35px",
                                  marginRight: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <EmailIcon
                                  style={{
                                    color: "white",
                                    fontSize: "30px",
                                  }}
                                />
                              </div>
                              Email
                            </div>
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              {profile.email}
                            </div>
                          </div>
                        ) : (
                          <Fragment></Fragment>
                        )}

                        {profile.gender.length != 0 ? (
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "normal",
                              color: "black",
                              display: "flex",
                              borderBottom: "1px solid #ddd",
                              paddingBottom: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#8856a7",
                                  padding: "4px",
                                  borderRadius: "50px",
                                  height: "35px",
                                  width: "35px",
                                  marginRight: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <TransgenderIcon
                                  style={{
                                    color: "white",
                                    fontSize: "30px",
                                  }}
                                />
                              </div>
                              Gender
                            </div>
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              {profile.gender}
                            </div>
                          </div>
                        ) : (
                          <Fragment></Fragment>
                        )}
                        {profile.address.length != 0 ? (
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "normal",
                              color: "black",
                              display: "flex",
                              borderBottom: "1px solid #ddd",
                              paddingBottom: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#43a2ca",
                                  padding: "4px",
                                  borderRadius: "50px",
                                  height: "35px",
                                  width: "35px",
                                  marginRight: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <HomeIcon
                                  style={{
                                    color: "white",
                                    fontSize: "30px",
                                  }}
                                />
                              </div>
                              Address
                            </div>
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              {profile.address}
                            </div>
                          </div>
                        ) : (
                          <Fragment></Fragment>
                        )}
                        {profile.blood_group.length != 0 ? (
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "normal",
                              color: "black",
                              display: "flex",
                              borderBottom: "1px solid #ddd",
                              paddingBottom: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#e34a33",
                                  padding: "4px",
                                  borderRadius: "50px",
                                  height: "35px",
                                  width: "35px",
                                  marginRight: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <BloodtypeIcon
                                  style={{
                                    color: "white",
                                    fontSize: "30px",
                                  }}
                                />
                              </div>
                              Blood Group
                            </div>
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              {profile.blood_group}
                            </div>
                          </div>
                        ) : (
                          <Fragment></Fragment>
                        )}
                        {profile.mother_tongue.length != 0 ? (
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "normal",
                              color: "black",
                              display: "flex",
                              borderBottom: "1px solid #ddd",
                              paddingBottom: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#1c9099",
                                  padding: "4px",
                                  borderRadius: "50px",
                                  height: "35px",
                                  width: "35px",
                                  marginRight: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <LanguageIcon
                                  style={{
                                    color: "white",
                                    fontSize: "30px",
                                  }}
                                />
                              </div>
                              Mother Tongue
                            </div>
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              {profile.mother_tongue}
                            </div>
                          </div>
                        ) : (
                          <Fragment></Fragment>
                        )}
                        {profile.birthday.length != 0 ? (
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "normal",
                              color: "black",
                              display: "flex",
                              borderBottom: "1px solid #ddd",
                              paddingBottom: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#dd1c77",
                                  padding: "4px",
                                  borderRadius: "50px",
                                  height: "35px",
                                  width: "35px",
                                  marginRight: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <CakeIcon
                                  style={{
                                    color: "white",
                                    fontSize: "30px",
                                  }}
                                />
                              </div>
                              Birthday
                            </div>
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              {profile.birthday}
                            </div>
                          </div>
                        ) : (
                          <Fragment></Fragment>
                        )}
                        {profile.birthmark.length != 0 ? (
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "normal",
                              color: "black",
                              display: "flex",
                              paddingBottom: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#636363",
                                  padding: "4px",
                                  borderRadius: "50px",
                                  height: "35px",
                                  width: "35px",
                                  marginRight: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <PersonIcon
                                  style={{
                                    color: "white",
                                    fontSize: "30px",
                                  }}
                                />
                              </div>
                              Birthmark
                            </div>
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              {profile.birthmark}
                            </div>
                          </div>
                        ) : (
                          <Fragment></Fragment>
                        )}
                      </div>
                    </div>
                    <Button
                      style={{
                        cursor: "pointer",
                        fontSize: "20px",
                        color: "black",
                      }}
                      onClick={() => {}}
                    >
                      Call
                    </Button>
                  </a>
                </div>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "left",
                    marginBottom: "15px",
                    color: "#145BC7",
                    borderBottom: "2px solid #145BC7",
                    paddingBottom: "15px",
                  }}
                >
                  Parents/Guardian Details
                </div>
                {profile.mother_name.length != 0 ||
                profile.mother_phone.length != 0 ||
                profile.mother_office_phone.length != 0 ||
                profile.mother_occupation.length != 0 ||
                profile.mother_office_address.length != 0 ? (
                  <div style={{ marginBottom: "15px" }}>
                    <div
                      style={{
                        textDecoration: "none !important",
                        padding: "15px",
                        color: "#145BC7",
                        marginBottom: "15px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                            width: "100%",
                          }}
                        >
                          {profile.mother_name.length != 0 ? (
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: "normal",
                                color: "black",
                                display: "flex",
                                borderBottom: "1px solid #ddd",
                                paddingBottom: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#2ca25f",
                                    padding: "4px",
                                    borderRadius: "50px",
                                    height: "35px",
                                    width: "35px",
                                    marginRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FamilyRestroomIcon
                                    style={{
                                      color: "white",
                                      fontSize: "30px",
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    color: "#145BC7",
                                  }}
                                >
                                  Mother
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                {profile.mother_name}
                              </div>
                            </div>
                          ) : (
                            <Fragment></Fragment>
                          )}

                          {profile.mother_phone.length != 0 ? (
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: "normal",
                                color: "black",
                                display: "flex",
                                paddingBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#253494",
                                    padding: "4px",
                                    borderRadius: "50px",
                                    height: "35px",
                                    width: "35px",
                                    marginRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <PhoneInTalkIcon
                                    style={{
                                      color: "white",
                                      fontSize: "30px",
                                    }}
                                  />
                                </div>
                                Phone Number
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                {profile.mother_phone}
                              </div>
                            </div>
                          ) : (
                            <Fragment></Fragment>
                          )}
                          {profile.mother_office_phone.length != 0 ? (
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: "normal",
                                color: "black",
                                display: "flex",
                                paddingBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#8856a7",
                                    padding: "4px",
                                    borderRadius: "50px",
                                    height: "35px",
                                    width: "35px",
                                    marginRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <PhoneInTalkIcon
                                    style={{
                                      color: "white",
                                      fontSize: "30px",
                                    }}
                                  />
                                </div>
                                Mother Office Phone
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                {profile.mother_office_phone}
                              </div>
                            </div>
                          ) : (
                            <Fragment></Fragment>
                          )}
                          {profile.mother_occupation.length != 0 ? (
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: "normal",
                                color: "black",
                                display: "flex",
                                paddingBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#3182bd",
                                    padding: "4px",
                                    borderRadius: "50px",
                                    height: "35px",
                                    width: "35px",
                                    marginRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <WorkIcon
                                    style={{
                                      color: "white",
                                      fontSize: "30px",
                                    }}
                                  />
                                </div>
                                Occupation
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                {profile.mother_occupation}
                              </div>
                            </div>
                          ) : (
                            <Fragment></Fragment>
                          )}
                          {profile.mother_office_address.length != 0 ? (
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: "normal",
                                color: "black",
                                display: "flex",
                                paddingBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#de2d26",
                                    padding: "4px",
                                    borderRadius: "50px",
                                    height: "35px",
                                    width: "35px",
                                    marginRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <ApartmentIcon
                                    style={{
                                      color: "white",
                                      fontSize: "30px",
                                    }}
                                  />
                                </div>
                                Office Address
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                {profile.mother_office_address}
                              </div>
                            </div>
                          ) : (
                            <Fragment></Fragment>
                          )}
                        </div>
                      </div>
                      <div style={{ marginRight: "-20px" }}>
                        {profile.mother_phone.length != 0 ? (
                          <a href={"tel://" + profile.mother_phone}>
                            <Button
                              style={{
                                cursor: "pointer",
                                fontSize: "18px",
                                color: "black",
                              }}
                              onClick={() => {}}
                            >
                              phone
                            </Button>
                          </a>
                        ) : (
                          <Fragment></Fragment>
                        )}
                        {profile.mother_office_phone.length != 0 ? (
                          <a href={"tel://" + profile.mother_office_phone}>
                            <Button
                              style={{
                                cursor: "pointer",
                                fontSize: "18px",
                                color: "black",
                              }}
                              onClick={() => {}}
                            >
                              office
                            </Button>
                          </a>
                        ) : (
                          <Fragment></Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <Fragment></Fragment>
                )}
                {profile.father_name.length != 0 ||
                profile.father_phone.length != 0 ||
                profile.father_office_phone.length != 0 ||
                profile.father_occupation.length != 0 ||
                (profile.father_office_address.length != 0) != 0 ? (
                  <div style={{ marginBottom: "15px" }}>
                    <div
                      style={{
                        textDecoration: "none !important",
                        padding: "15px",
                        color: "#145BC7",
                        marginBottom: "15px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <div style={{ textAlign: "left", width: "100%" }}>
                          {profile.father_name.length != 0 ? (
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: "normal",
                                color: "black",
                                display: "flex",
                                borderBottom: "1px solid #ddd",
                                paddingBottom: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#2ca25f",
                                    padding: "4px",
                                    borderRadius: "50px",
                                    height: "35px",
                                    width: "35px",
                                    marginRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FamilyRestroomIcon
                                    style={{
                                      color: "white",
                                      fontSize: "30px",
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    color: "#145BC7",
                                  }}
                                >
                                  Father
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                {profile.father_name}
                              </div>
                            </div>
                          ) : (
                            <Fragment></Fragment>
                          )}

                          {profile.father_phone.length != 0 ? (
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: "normal",
                                color: "black",
                                display: "flex",
                                paddingBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#253494",
                                    padding: "4px",
                                    borderRadius: "50px",
                                    height: "35px",
                                    width: "35px",
                                    marginRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <PhoneInTalkIcon
                                    style={{
                                      color: "white",
                                      fontSize: "30px",
                                    }}
                                  />
                                </div>
                                Phone Number
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                {profile.father_phone}
                              </div>
                            </div>
                          ) : (
                            <Fragment></Fragment>
                          )}
                          {profile.father_office_phone.length != 0 ? (
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: "normal",
                                color: "black",
                                display: "flex",
                                paddingBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#8856a7",
                                    padding: "4px",
                                    borderRadius: "50px",
                                    height: "35px",
                                    width: "35px",
                                    marginRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <PhoneInTalkIcon
                                    style={{
                                      color: "white",
                                      fontSize: "30px",
                                    }}
                                  />
                                </div>
                                father Office Phone
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                {profile.father_office_phone}
                              </div>
                            </div>
                          ) : (
                            <Fragment></Fragment>
                          )}
                          {profile.father_occupation.length != 0 ? (
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: "normal",
                                color: "black",
                                display: "flex",
                                paddingBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#3182bd",
                                    padding: "4px",
                                    borderRadius: "50px",
                                    height: "35px",
                                    width: "35px",
                                    marginRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <WorkIcon
                                    style={{
                                      color: "white",
                                      fontSize: "30px",
                                    }}
                                  />
                                </div>
                                Occupation
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                {profile.father_occupation}
                              </div>
                            </div>
                          ) : (
                            <Fragment></Fragment>
                          )}
                          {profile.father_office_address.length != 0 ? (
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: "normal",
                                color: "black",
                                display: "flex",
                                paddingBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#de2d26",
                                    padding: "4px",
                                    borderRadius: "50px",
                                    height: "35px",
                                    width: "35px",
                                    marginRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <ApartmentIcon
                                    style={{
                                      color: "white",
                                      fontSize: "30px",
                                    }}
                                  />
                                </div>
                                Office Address
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                {profile.father_office_address}
                              </div>
                            </div>
                          ) : (
                            <Fragment></Fragment>
                          )}
                        </div>
                      </div>
                      <div style={{ marginRight: "-20px" }}>
                        {profile.father_phone.length != 0 ? (
                          <a href={"tel://" + profile.father_phone}>
                            <Button
                              style={{
                                cursor: "pointer",
                                fontSize: "18px",
                                color: "black",
                              }}
                              onClick={() => {}}
                            >
                              phone
                            </Button>
                          </a>
                        ) : (
                          <Fragment></Fragment>
                        )}
                        {profile.father_office_phone.length != 0 ? (
                          <a href={"tel://" + profile.father_office_phone}>
                            <Button
                              style={{
                                cursor: "pointer",
                                fontSize: "18px",
                                color: "black",
                              }}
                              onClick={() => {}}
                            >
                              office
                            </Button>
                          </a>
                        ) : (
                          <Fragment></Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <Fragment></Fragment>
                )}

                {profile.guardian_name.length != 0 ||
                profile.guardian_phone.length != 0 ||
                profile.guardian_office_phone.length != 0 ||
                profile.guardian_occupation.length != 0 ||
                profile.guardian_office_address.length != 0 ? (
                  <div style={{ marginBottom: "15px" }}>
                    <div
                      style={{
                        textDecoration: "none !important",
                        padding: "15px",
                        color: "#145BC7",
                        marginBottom: "15px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <div style={{ textAlign: "left", width: "100%" }}>
                          {profile.guardian_name.length != 0 ? (
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: "normal",
                                color: "black",
                                display: "flex",
                                borderBottom: "1px solid #ddd",
                                paddingBottom: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#2ca25f",
                                    padding: "4px",
                                    borderRadius: "50px",
                                    height: "35px",
                                    width: "35px",
                                    marginRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FamilyRestroomIcon
                                    style={{
                                      color: "white",
                                      fontSize: "30px",
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    color: "#145BC7",
                                  }}
                                >
                                  Guardian
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                {profile.guardian_name}
                              </div>
                            </div>
                          ) : (
                            <Fragment></Fragment>
                          )}

                          {profile.guardian_phone.length != 0 ? (
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: "normal",
                                color: "black",
                                display: "flex",
                                paddingBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#253494",
                                    padding: "4px",
                                    borderRadius: "50px",
                                    height: "35px",
                                    width: "35px",
                                    marginRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <PhoneInTalkIcon
                                    style={{
                                      color: "white",
                                      fontSize: "30px",
                                    }}
                                  />
                                </div>
                                Phone Number
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                {profile.guardian_phone}
                              </div>
                            </div>
                          ) : (
                            <Fragment></Fragment>
                          )}
                          {profile.guardian_office_phone.length != 0 ? (
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: "normal",
                                color: "black",
                                display: "flex",
                                paddingBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#8856a7",
                                    padding: "4px",
                                    borderRadius: "50px",
                                    height: "35px",
                                    width: "35px",
                                    marginRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <PhoneInTalkIcon
                                    style={{
                                      color: "white",
                                      fontSize: "30px",
                                    }}
                                  />
                                </div>
                                guardian Office Phone
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                {profile.guardian_office_phone}
                              </div>
                            </div>
                          ) : (
                            <Fragment></Fragment>
                          )}
                          {profile.guardian_occupation.length != 0 ? (
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: "normal",
                                color: "black",
                                display: "flex",
                                paddingBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#3182bd",
                                    padding: "4px",
                                    borderRadius: "50px",
                                    height: "35px",
                                    width: "35px",
                                    marginRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <WorkIcon
                                    style={{
                                      color: "white",
                                      fontSize: "30px",
                                    }}
                                  />
                                </div>
                                Occupation
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                {profile.guardian_occupation}
                              </div>
                            </div>
                          ) : (
                            <Fragment></Fragment>
                          )}
                          {profile.guardian_office_address.length != 0 ? (
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: "normal",
                                color: "black",
                                display: "flex",
                                paddingBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#de2d26",
                                    padding: "4px",
                                    borderRadius: "50px",
                                    height: "35px",
                                    width: "35px",
                                    marginRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <ApartmentIcon
                                    style={{
                                      color: "white",
                                      fontSize: "30px",
                                    }}
                                  />
                                </div>
                                Office Address
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                {profile.guardian_office_address}
                              </div>
                            </div>
                          ) : (
                            <Fragment></Fragment>
                          )}
                        </div>
                      </div>
                      <div style={{ marginRight: "-20px" }}>
                        {profile.guardian_phone.length != 0 ? (
                          <a href={"tel://" + profile.guardian_phone}>
                            <Button
                              style={{
                                cursor: "pointer",
                                fontSize: "18px",
                                color: "black",
                              }}
                              onClick={() => {}}
                            >
                              phone
                            </Button>
                          </a>
                        ) : (
                          <Fragment></Fragment>
                        )}
                        {profile.guardian_office_phone.length != 0 ? (
                          <a href={"tel://" + profile.guardian_office_phone}>
                            <Button
                              style={{
                                cursor: "pointer",
                                fontSize: "18px",
                                color: "black",
                              }}
                              onClick={() => {}}
                            >
                              office
                            </Button>
                          </a>
                        ) : (
                          <Fragment></Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <Fragment></Fragment>
                )}

                {profile.class_teacher_name.length != 0 ||
                profile.class_teacher_phone.length != 0 ? (
                  <div style={{ marginBottom: "15px" }}>
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        textAlign: "left",
                        marginBottom: "15px",
                        color: "#145BC7",
                        borderBottom: "2px solid #145BC7",
                        paddingBottom: "15px",
                      }}
                    >
                      Class Teacher Details
                    </div>
                    <div
                      style={{
                        textDecoration: "none !important",
                        padding: "15px",
                        color: "#145BC7",
                        marginBottom: "15px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          marginRight: "20px",
                        }}
                      >
                        {profile.class_teacher_name.length != 0 ? (
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "normal",
                              color: "black",
                              display: "flex",
                              borderBottom: "1px solid #ddd",
                              paddingBottom: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#54278f",
                                  padding: "4px",
                                  borderRadius: "50px",
                                  height: "35px",
                                  width: "35px",
                                  marginRight: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <PersonIcon
                                  style={{
                                    color: "white",
                                    fontSize: "30px",
                                  }}
                                />
                              </div>
                              Class Teacher Name
                            </div>
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              {profile.class_teacher_name}
                            </div>
                          </div>
                        ) : (
                          <Fragment></Fragment>
                        )}

                        {profile.class_teacher_phone.length != 0 ? (
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "normal",
                              color: "black",
                              display: "flex",
                              paddingBottom: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#253494",
                                  padding: "4px",
                                  borderRadius: "50px",
                                  height: "35px",
                                  width: "35px",
                                  marginRight: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <PhoneInTalkIcon
                                  style={{
                                    color: "white",
                                    fontSize: "30px",
                                  }}
                                />
                              </div>
                              Phone Number
                            </div>
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              {profile.class_teacher_phone}
                            </div>
                          </div>
                        ) : (
                          <Fragment></Fragment>
                        )}
                      </div>
                      {profile.class_teacher_phone.length != 0 ? (
                        <a href={"tel://" + profile.class_teacher_phone}>
                          <Button
                            style={{
                              cursor: "pointer",
                              fontSize: "18px",
                              color: "black",
                            }}
                            onClick={() => {}}
                          >
                            Call
                          </Button>
                        </a>
                      ) : (
                        <Fragment></Fragment>
                      )}
                    </div>
                  </div>
                ) : (
                  <Fragment></Fragment>
                )}

                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "left",
                    marginBottom: "15px",
                    color: "#145BC7",
                    borderBottom: "2px solid #145BC7",
                    paddingBottom: "15px",
                  }}
                >
                  School Information
                </div>
                {profile.school_email.length != 0 ||
                profile.principal_name.length != 0 ||
                profile.school_phone.length != 0 ? (
                  <div style={{ marginBottom: "15px" }}>
                    <div
                      style={{
                        textDecoration: "none !important",
                        padding: "15px",
                        color: "#145BC7",
                        marginBottom: "15px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <div style={{ textAlign: "left", width: "100%" }}>
                          {profile.school_name.length != 0 ? (
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: "normal",
                                color: "black",
                                display: "flex",
                                borderBottom: "1px solid #ddd",
                                paddingBottom: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#54278f",
                                    padding: "4px",
                                    borderRadius: "50px",
                                    height: "35px",
                                    width: "35px",
                                    marginRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <SchoolIcon
                                    style={{
                                      color: "white",
                                      fontSize: "30px",
                                    }}
                                  />
                                </div>
                                School Name
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                {profile.school_name}
                              </div>
                            </div>
                          ) : (
                            <Fragment></Fragment>
                          )}
                          {profile.principal_name.length != 0 ? (
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: "normal",
                                color: "black",
                                display: "flex",
                                borderBottom: "1px solid #ddd",
                                paddingBottom: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#bd0026",
                                    padding: "4px",
                                    borderRadius: "50px",
                                    height: "35px",
                                    width: "35px",
                                    marginRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <PersonIcon
                                    style={{
                                      color: "white",
                                      fontSize: "30px",
                                    }}
                                  />
                                </div>
                                Principal Name
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                {profile.principal_name}
                              </div>
                            </div>
                          ) : (
                            <Fragment></Fragment>
                          )}
                          {profile.school_email.length != 0 ? (
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: "normal",
                                color: "black",
                                display: "flex",
                                borderBottom: "1px solid #ddd",
                                paddingBottom: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#31a354",
                                    padding: "4px",
                                    borderRadius: "50px",
                                    height: "35px",
                                    width: "35px",
                                    marginRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <EmailIcon
                                    style={{
                                      color: "white",
                                      fontSize: "30px",
                                    }}
                                  />
                                </div>
                                School Email
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                {profile.school_email}
                              </div>
                            </div>
                          ) : (
                            <Fragment></Fragment>
                          )}
                          {profile.school_phone.length != 0 ? (
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: "normal",
                                color: "black",
                                display: "flex",
                                paddingBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#253494",
                                    padding: "4px",
                                    borderRadius: "50px",
                                    height: "35px",
                                    width: "35px",
                                    marginRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <PhoneInTalk
                                    style={{
                                      color: "white",
                                      fontSize: "30px",
                                    }}
                                  />
                                </div>
                                School Phone
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  alignItems: "center",
                                }}
                              >
                                {profile.school_phone}
                              </div>
                            </div>
                          ) : (
                            <Fragment></Fragment>
                          )}
                        </div>
                      </div>
                      {profile.school_phone.length != 0 ? (
                        <a href={"tel://" + profile.school_phone}>
                          <Button
                            style={{
                              cursor: "pointer",
                              fontSize: "18px",
                              color: "black",
                            }}
                            onClick={() => {}}
                          >
                            Call
                          </Button>
                        </a>
                      ) : (
                        <Fragment></Fragment>
                      )}
                    </div>
                  </div>
                ) : (
                  <Fragment></Fragment>
                )}
              </Grid>
              <Grid item sm={1} md={2} lg={3} style={{ width: "95%" }}></Grid>
            </Grid>
            <div
              style={{
                padding: "3px 10px 3px 10px",
                backgroundColor: "rgba(0,0,0,0.6)",
                fontSize: "20px",
                fontWeight: "bold",
                textDecoration: "none",
                color: "white",
                marginTop: "25px",
              }}
            >
              Download
            </div>
            <div
              style={{
                marginTop: "-25px",
                zIndex: "-1",
                marginBottom: "25px",
                width: "98%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "120px",
                backgroundColor: "#F4F5FA",
              }}
            >
              <img
                style={{
                  objectFit: "cover",
                  height: "60px",
                }}
                src={logo}
              />
            </div>
            <a
              href="https://apps.apple.com/in/app/"
              style={{
                paddingLeft: "5px",
                paddingRight: "5px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
                backgroundColor: "#F4F5FA",
                marginTop: "-45px",
              }}
            >
              <img
                style={{
                  objectFit: "cover",
                  height: "60px",
                }}
                src={appstore}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id"
              style={{
                paddingLeft: "5px",
                paddingRight: "5px",
                marginBottom: "25px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
                backgroundColor: "#F4F5FA",
                marginTop: "-15px",
              }}
            >
              <img
                style={{
                  objectFit: "cover",
                  height: "60px",
                }}
                src={playstore}
              />
            </a>
            {alert ? (
              <Snackbar
                open={alert}
                autoHideDuration={3000}
                onClose={() => setAlert(false)}
                message="Content copied to Clipboard."
              />
            ) : (
              <Fragment></Fragment>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="App">
          <div
            style={{
              height: "100vh",
              width: "98vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#145BC7",
            }}
          >
            <Lottie
              style={{ height: "300px", width: "300px" }}
              animationData={notFound}
              loop={true}
            />
          </div>
        </div>
      );
    }
  }
}

export default App;
